.title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 20vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    //background-color: aliceblue;

    .about__title {
        z-index: 3;
        position: relative;
        margin-left: 10%;
    }
    .about__blob {
        height: 107px;
        width: 107px;
        position: absolute;
        top: 4vh;
        left: 5vw;
        z-index: 1;
    }
}

@media only screen and (min-width: 667px) {
    .title {
        height: 25vh;

        .about__title{
            font-size: 40px;
        }
    }
}

@media only screen and (min-width: 768px) {
    .title {
        height: 18vh;

        .about__title{
            font-size: 60px;
        }
        .about__blob {
            height: 137px;
            width: 137px;
        }
    }
}

@media only screen and (min-width: 812px) {
    .title {
        height: 30vh;

        .about__title{
            font-size: 40px;
        }
        .about__blob {
            height: 107px;
            width: 107px;
        }
    }
}

@media only screen and (min-width: 1024px) {
    .title {
        height: 25vh;

        .about__title{
            font-size: 60px;
        }
        .about__blob {
            height: 157px;
            width: 157px;
        }
    }
}

@media only screen and (min-width: 1440px) {
    .title {
        height: 25vh;

        .about__title{
            font-size: 70px;
        }
        .about__blob {
            height: 177px;
            width: 177px;
        }
    }
}