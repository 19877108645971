.header__animation {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100vh;
           
    .blob1 {
        position: absolute;
        top: -10%;
        left: -20%;
        animation: move 10s ease-in-out infinite;

        svg{
        width:298px;
        height:318px;
        }
    }

    .blob2 {
        position: absolute;
        bottom: 0;
        right: -20%;
        animation: move 10s ease-in-out infinite;

        svg{
        width:340px;
        height:308px;
        }
    }
    @keyframes move {
        0% , 100%   { transform: scale(1)   translate(10px, -10px); }
        38%  { transform: scale(0.95) translate(0.5vw, 1vh) rotate(10deg); }
        40%  { transform: scale(0.95) translate(0.5vw,1vh) rotate(10deg); }
        78%  { transform: scale(1) translate(0vw, -1vh) rotate(-10deg); }
        80%  { transform: scale(1) translate(0vw, -1vh) rotate(-10deg); }
         } 
}

@media only screen and (min-width: 667px) {
    .header__animation {
        .blob1 {
            top: -10%;
            left: -15%;
    
            svg{
            width:298px;
            height:318px;
            }
        }
    
        .blob2 {
            bottom: -10%;
            right: -10%;
            
            svg{
            width:340px;
            height:308px;
            }
        }
        @keyframes move {
            0% , 100%   { transform: scale(1)   translate(5px, -10px); }
            38%  { transform: scale(0.95) translate(0.5vw, 1vh) rotate(10deg); }
            40%  { transform: scale(0.95) translate(0.5vw,1vh) rotate(10deg); }
            78%  { transform: scale(1) translate(0vw, -1vh) rotate(-10deg); }
            80%  { transform: scale(1) translate(0vw, -1vh) rotate(-10deg); }
             } 
    }
}

@media only screen and (min-width: 768px) {
    .header__animation {
        .blob1 {
            top: -20%;
            left: -15%;
    
            svg{
            width:598px;
            height:618px;
            }
        }
    
        .blob2 {
            bottom: -10%;
            right: -15%;
            
            svg{
            width:680px;
            height:648px;
            }
        }
        @keyframes move {
            0% , 100%   { transform: scale(1)   translate(5px, -10px); }
            38%  { transform: scale(0.9) translate(0.2vw, 1vh) rotate(10deg); }
            40%  { transform: scale(0.9) translate(0.2vw,1vh) rotate(10deg); }
            78%  { transform: scale(1) translate(0vw, -1vh) rotate(-5deg); }
            80%  { transform: scale(1) translate(0vw, -1vh) rotate(-5deg); }
        } 
    }
}

@media only screen and (min-width: 812px) {
  
}

@media only screen and (min-width: 1024px) {
    
}

@media only screen and (min-width: 1440px) {
    .header__animation {
        .blob1 {
            width:518px;
            height:548px;
    
            position: absolute;
            top: 13%;
            left: 19%;
            z-index: 1;
            animation: move 10s ease-in-out infinite;
            transform-origin: 50% 50%;
        }
        
        .blob2 {
            width:700px;
            height:558px;
            position: absolute;
            top: 13%;
            left: 35%;
            animation: move 10s ease-in-out infinite;
            transform-origin: 50% 50%;
        }
    }
    
    @keyframes move {
        0%   { transform: scale(1)   translate(10px, -20px); }
        38%  { transform: scale(0.8, 0.9) translate(4vw, 2vh) rotate(15deg); }
        40%  { transform: scale(0.8, 0.9) translate(4vw, 2vh) rotate(15deg); }
        78%  { transform: scale(0.9) translate(0vw, -6vh) rotate(-20deg); }
        80%  { transform: scale(0.9) translate(0vw, -6vh) rotate(-20deg); }
        100% { transform: scale(1)   translate(10px, -20px); } 
      } 
}