@import '../../../assets/styles/_variables.scss';
@import '../../../assets/styles/_typography.scss';

    
    .mobileNav{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 15;
        width: 100%;
        height: 10vh;

       
        .openbtn {
            font-size: 40px;
            cursor: pointer;
            background-color: transparent;
            color: black;
            padding: 10px 15px;
            border: none;
        }
        
    }
        

    .sidepanel {
        height: 100vh; 
        width: 100%; 
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        background-color:  $colour-ocre;
        overflow-x: hidden; 
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .closebtn {
            cursor: pointer;
            background: transparent;
            border: 0;
            position: absolute;
            top: 10px;
            right: 25px;
            font-size: 36px;
            margin-left: 50px;
            color: $colour-grey;
        }

        ul {
            height: 100vh;
            width: 90%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            

            li {
                margin: 1em;
                a {
                    color: $colour-white;
                    font-size: 60px;
                    &:hover, &:active {
                        color: $colour-grey;
                    }
                }
            }
        }
    }

