@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';
/* navigation */

nav {
    z-index: 20;
    position: fixed;
    height: 45px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;


    ul {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        list-style-type: none;
        margin-right: 10px;

        li {
            padding: 0px 20px;
            transition: color 0.4s ease-out;
    
    
            a {
                font-size: $font-S;
                text-decoration: none;
                cursor: pointer;
            }

            .light {
                color: $colour-white;
            }
    
            .light:hover {
                color: $colour-ocre;
            }
            .dark {
                color: $colour-ocre;
            }
    
            .dark:hover {
                color: $colour-grey;
            }
        }
    }

}
