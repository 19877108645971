@import "../../assets/styles/_variables.scss";
@import "../../assets/styles/_typography.scss";

/* PROJECTS SECTION */
main {
  z-index: 2;
}

.projects__title {
  color: $colour-grey;
  margin-top: 70px;
  text-align: left;
  margin-left: 10%;
  //   margin-bottom: 50px;
  margin-bottom: 0.5em;
}

.projects {
  min-height: 100vh;
  width: 100%;
  text-align: left;
  display: flex;
  //   flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 5%;

  .project {
    display: flex;
    // flex-direction: column;
    // justify-content: flex-start;
    justify-content: space-around;
    align-items: center;
    // min-height: 600px;
    // width: 40%;
    height: 20%;
    width: 90%;
    margin: 0 auto 80px;

    .project__image {
      margin-bottom: 25px;
      height: auto;
      width: 400px;
      border: 0;
      outline: 0;
      border-radius: 5px;
      box-shadow: -5px -5px 15px $color-white, 5px 5px 15px $color-shadow;
      transition: all 0.2s ease-in-out;

      &:hover {
        box-shadow: -2px -2px 5px $color-white, 2px 2px 5px $color-shadow;
      }
    }

    .project__description {
      text-align: left;
      //   width: 400px;
      width: 45%;
      display: flex;
      flex-direction: column;
      //   justify-content: flex-start;
      justify-content: center;
      align-items: flex-start;

      h4 {
        margin-bottom: 20px;
        color: $colour-ocre;
      }

      p {
        width: 100%;
        line-height: 25px;
        color: #9d9d9d;
      }

      ul {
        margin: 20px 0 0;
        width: 100%;

        display: flex;
        justify-content: space-evenly;
        align-items: center;

        li {
          margin: 20px;
          list-style-type: none;

          svg {
            font-size: $font-M;
            color: $colour-light-grey;

            &:hover {
              color: $colour-grey;
            }
          }
        }
      }
      .project__links {
        margin-top: 5%;
        width: 100%;

        display: flex;
        justify-content: space-around;
        align-items: center;

        button {
          width: 30%;
          border: 0;
          outline: 0;
          font-size: 15px;
          border-radius: $ruler * 20;
          padding: 12px;
          cursor: pointer;
          font-weight: 600;
          background: #a4742c;

          &:hover {
            box-shadow: -2px -2px 5px $color-white, 2px 2px 5px $color-shadow;
            background: linear-gradient(145deg, #946828, #af7c2f);
          }

          &:active {
            box-shadow: inset 1px 1px 2px $color-shadow,
              inset -1px -1px 2px $color-white;
            background: linear-gradient(145deg, #946828, #af7c2f);
          }

          a {
            font-size: 15px;
            color: $color-white;
            text-decoration: none;
          }
        }
      }
    }
  }
}

// tablet vertical

@media only screen and (max-width: 820px) {
  .projects__title {
    margin-bottom: 50px;
  }

  .projects {
    width: 100%;

    .project {
      height: fit-content;
      min-height: unset;
      width: 100%;
      .project__image {
        width: 300px;
      }

      .project__description {
        width: 50%;

        h4 {
          font-size: $font-S;
        }

        p {
          font-size: $font-XS;
          width: 98%;
        }
      }

      .project__description {
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 95%;
        h4 {
          font-size: $font-S;
        }

        p {
          font-size: $font-XS;
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 820px) {
  .projects__title {
    margin-top: 40px;
  }
  .projects {
    .project {
      flex-direction: column;
      justify-content: center;

      .project__description {
        width: 95%;
      }
    }
  }
}
