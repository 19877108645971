.header__text {
    z-index: 5;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    

    h1 {
        margin-bottom: 20px;
    }
}


@media only screen and (min-width: 812px) {
    .header__text {
        h1 {
            font-size: 70px;
        }
        h2 {
            font-size: 60px;
        }
    }
  
}

@media only screen and (min-width: 1024px) {
    .header__text {
        h1 {
            font-size: 90px;
        }
        h2 {
            font-size: 80px;
        }
    }
}

@media only screen and (min-width: 1440px) {
    .header__text {
        h1 {
            font-size: 120px;
        }
        h2 {
            font-size: 90px;
        }
    }
}